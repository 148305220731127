<template>
  <v-footer absolute="" id="dashboard-core-footer">
    <v-container>
      <v-row align="center" no-gutters>
        <v-col>
          <div class="font-weight-light text-right">
            Toyota Muangchon Company Limited. All rights reserved.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "DashboardCoreFooter"
};
</script>

<style lang="sass">
#dashboard-core-footer
  a
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
</style>
